// =======================================
//     List of variables for layout
// =======================================

$header-height: 70px;
$sidebar-width: 255px;
$sidebar-collapsed-width: 70px;

// header
$header-background: #fff;
$header-color: #525b69;
$header-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
$brand-color: #161c25;
$header-item-border-color: #CECECE;


// Menu
$sidebar-background: #1c232f;
$sidebar-color: #ced4dc;
$sidebar-icon-color: #778290;
$sidebar-main-active-color: #fff;
$sidebar-active-color: $primary;
$sidebar-caption: $header-color;
$sidebar-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

// horizontal menu
$topbar-height: 60px;
$topbar-background: #1c232f;
$topbar-color: #b5bdca;
$header-submenu-background: #fff;
$header-submenu-color: #1c232f;

// card block
$card-shadow: 0 6px 30px rgba(182, 186, 203, 0.3);

$soft-bg-level: -80%;

$themes-colors: (
   // { For Gradient effect}
  // theme-1: (
  //   body: linear-gradient(115.25deg, #ffffff 0%, #f8f9fd 100.25%),
  //   primary: #51459d,
  //   primary-grd:
  //     linear-gradient(141.55deg,rgba(81, 69, 157, 0) 3.46%,rgba(255, 58, 110, 0.6) 99.86%),
  // ),
// { For Plain effect}
  // theme-1: (
  //   body:
  //     linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
  //   primary: #584ed2,
  //   primary-grd:
  //     linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685ee5 99.86%),
  // ),

  theme-1: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #0CAF60,
    primary-grd:
      linear-gradient(141.55deg, #0CAF60 3.46%, #0CAF60 99.86%),
  ),

  theme-2: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #75C251,
    primary-grd:
      linear-gradient(141.55deg, #75C251 3.46%, #75C251 99.86%),
  ),

  theme-3: (
    body: linear-gradient(115.25deg, #ffffff 0%, #f8f9fd 100.25%),
    primary: #584ED2,
    primary-grd: linear-gradient(141.55deg, #584ED2 3.46%, #584ED2 99.86%),
  ),

  theme-4: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #145388,
    primary-grd:linear-gradient(141.55deg, #145388 3.46%, #145388 99.86%),
  ),
  theme-5: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #B9406B,
    primary-grd: linear-gradient(141.55deg, #B9406B 3.46%, #B9406B 99.86%),
  ),
  theme-6: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #008ECC,
    primary-grd:linear-gradient(141.55deg, #008ECC 3.46%, #008ECC 99.86%),
  ),
  theme-7: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #922C88,
    primary-grd:linear-gradient(141.55deg, #922C88 3.46%, #922C88 99.86%),
  ),
  theme-8: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #C0A145,
    primary-grd:linear-gradient(141.55deg, #C0A145 3.46%, #C0A145 99.86%),
  ),
  theme-9: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #48494B,
    primary-grd:linear-gradient(141.55deg, #48494B 3.46%, #48494B 99.86%),
  ),
  theme-10: (
    body:
      linear-gradient(141.55deg, rgba(240, 244, 243, 0) 3.46%, #f0f4f3 99.86%),
    primary: #0C7785,
    primary-grd:linear-gradient(141.55deg, #0C7785 3.46%, #0C7785 99.86%),
  ),
);
